import React from 'react';
import PropTypes from 'prop-types';

function MessageBubble({ role, content }) {
  // Insert newlines before numbered items (e.g., "1. ", "2. ", etc.)
  // Adjust the regex if your content uses different patterns
  let cleanedContent = content.replace(/(\d+\.\s)/g, '\n$1');

  // Convert markdown bold (**text**) to <strong>text</strong>
  cleanedContent = cleanedContent.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

  // Split on newline characters, trim lines, and filter out empties
  const lines = cleanedContent
    .split('\n')
    .map(line => line.trim())
    .filter(line => line.length > 0);

  return (
    <div className={`message-bubble ${role}`}>
      {lines.map((line, index) => (
        <p key={index} dangerouslySetInnerHTML={{ __html: line }} />
      ))}
    </div>
  );
}

MessageBubble.propTypes = {
  role: PropTypes.oneOf(['user', 'bot']).isRequired,
  content: PropTypes.string.isRequired
};

export default MessageBubble;
