import React, { useState } from 'react';
import MessageBubble from './MessageBubble';
import './App.css';

function App() {
  const [messages, setMessages] = useState([]);
  const [question, setQuestion] = useState('');
  const [loading, setLoading] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL || 'https://chatbotsj-a3c9b167b597.herokuapp.com/api/ask';

  const handleSend = async () => {
    const trimmed = question.trim();
    if (!trimmed) return;

    // Add user message
    setMessages(prev => [...prev, { role: 'user', content: trimmed }]);
    setQuestion('');
    setLoading(true);

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ question: trimmed })
      });

      if (!response.ok) {
        const errData = await response.json();
        throw new Error(errData.error || 'Server error');
      }

      const data = await response.json();
      const answer = data.answer || 'No answer received.';

      // Add bot response
      setMessages(prev => [...prev, { role: 'bot', content: answer }]);
    } catch (error) {
      setMessages(prev => [...prev, { role: 'bot', content: `Error: ${error.message}` }]);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') handleSend();
  };

  return (
    <div className="app-container">
      <header className="header">
        <h1>CareConnect - HealthEquity ChatBot</h1>
      </header>
      <div className="chat-area">
        {messages.map((msg, i) => (
          <MessageBubble key={i} role={msg.role} content={msg.content} />
        ))}
        {loading && <MessageBubble role="bot" content="Thinking..." />}
      </div>
      <div className="input-bar">
        <input
          type="text"
          placeholder="Ask a question about healthcare disparities..."
          value={question}
          onChange={e => setQuestion(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button onClick={handleSend} disabled={loading}>
          Send
        </button>
      </div>
    </div>
  );
}

export default App;
